@import "../utilities/variables";

.noItems {
  background-color: $blue-lightest;
  height: calc(100% - 48px);
  margin: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    height: 187px;
  }
}

.noItemsMsg {
  padding: 12px;
}