.field {
  margin-bottom: 1rem;
  display: block;
  width: 100%;
}

.label {
  margin-bottom: 0.5rem;
  display: block;
}
