$blue-lightest: #f0f5f8;
$blue-lighter: #83b2d2;
$blue-default: #488dbc;
$blue-darker: #387096;
$blue-darkest: #2e5e7e;
$blue-logo: #298dc1;

$orange-lightest: #fff1e2;
$orange-lighter: #ffd098;
$orange-default: #f4a522;
$orange-darker: #e87e01;
$orange-darkest: #b34b00;

$green-dark: #219653;
$green-light: #e6f2ed;

$yellow-light: #fcf8d4;
$yellow-dark: #f2c94c;

$red-light: #ffefed;
$red-dark: #e11900;

$mono-white: #ffffff;
$mono-lightest: #fbfbfb;
$mono-lighter: #f2f2f2;
$mono-default-light: #e0e0e0;
$mono-default-dark: #bdbdbd;
$mono-darker: #828282;
$mono-darkest: #4f4f4f;
$mono-black: #333333;
$mono-text-black: #000000;
$mono-light-grey: #e2e2e266;

$grid__bp-xs: 0;
$grid__bp-sm: 576;
$grid__bp-md: 768;
$grid__bp-lg: 992;
$grid__bp-xl: 1200;
$grid__cols: 12;

$box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);

$container-padding-md: 16px;
$container-padding-lg: 32px;

$row-margin: 16px;
$col-margin: 16px;

$border-radius-lg: 24px;

$font-weight-md: 600;
