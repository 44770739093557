@import '../utilities/variables';

$map-grid-props: (
  '-xs': 0,
  '-sm': $grid__bp-sm,
  '-md': $grid__bp-md,
  '-lg': $grid__bp-lg,
  '-xl': $grid__bp-xl,
);

@mixin create-mq($breakpoint, $min-or-max) {
  @if ($breakpoint == 0) {
    @content;
  } @else {
    @media only screen and (#{$min-or-max}-width: #{$breakpoint}px) {
      @content;
    }
  }
}

@mixin create-col-classes($modifier, $grid-cols, $breakpoint) {
  @include create-mq($breakpoint, 'min') {
    &__col#{$modifier}-offset-0 {
      margin-left: 0;
    }
    @for $i from 1 through $grid-cols {
      &__col#{$modifier}-#{$i} {
        flex-basis: (100 / ($grid-cols / $i)) * 1%;
      }
      &__col#{$modifier}-offset-#{$i} {
        margin-left: (100 / ($grid-cols / $i)) * 1%;
      }
    }
  }
}

@each $modifier, $breakpoint in $map-grid-props {
  @include create-mq($breakpoint - 1, 'max') {
    .hidden#{$modifier}-down {
      display: none !important;
    }
  }
  @include create-mq($breakpoint, 'min') {
    .hidden#{$modifier}-up {
      display: none !important;
    }
  }
}

.container {
  max-width: 80rem;
  margin: 0 auto;
  &--fluid {
    margin: 0;
    max-width: 100%;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  @each $modifier, $breakpoint in $map-grid-props {
    @include create-col-classes($modifier, $grid__cols, $breakpoint);
  }
}
