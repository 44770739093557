@import '../utilities/variables';

.table {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.tableHeader {
  padding: 0;
  padding-bottom: 0.6rem;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1.5rem 1rem;
  }
}

.tableAction {
  padding: 0.75rem 0;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1rem;
  }
}
