@import '../utilities/variables';

.cardContainer {
  width: 100%;
  border: 2px solid $mono-lighter;
  padding: 0.75rem;
}

.mainInfoContainer {
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.expandedArrow {
  transform: rotate(180deg);
}

.description {
  flex-grow: 2;
}

.leftInfoContainer {
  width: 4.25rem;
  @media only screen and (max-width: #{$grid__bp-md}px) {
    display: none;
  }
}
.selectButton {
  min-width: 8.75rem;
}
.rightInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0.5rem;
  min-width: 9.25rem;
}

.rightAddServiceToggle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  @media only screen and (max-width: #{$grid__bp-md}px) {
    display: none;
  }
}

.mobileButtons {
  display: none;
  button {
    width: 100%;
  }
  @media only screen and (max-width: #{$grid__bp-md}px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 0;
    gap: 0.5rem;
  }
}

.mobileSelectButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  gap: 0.5rem;
  button {
    flex-grow: 1;
  }
}

.expandedInfoContainer {
  display: none;
  background-color: $mono-lighter;
  white-space: pre-wrap;

  &.containerExpanded {
    display: flex;
    padding: 1rem 1.5rem;
    margin-top: 0.5rem;
  }
}

.detailsCopy {
  white-space: pre-wrap;
}

.originalPrice {
  font-size: small
}
