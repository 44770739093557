@import '../utilities/variables';

.wrapper {
  flex: 1 1 0px;
  padding-top: 5%;
  @media only screen and (min-width: 767px) {
    background-image: url('../../images/gooil-car.png');
    background-repeat: no-repeat;
    background-position: 90% 90%;
    background-size: 40%;
  }
  @media only screen and (max-width: 767px) {
    flex-basis: auto;
  }
}

.label {
  color: $mono-black;
  margin-top: -0.5rem;
}

.card {
  padding: 2.5rem;
  background: rgba(255, 255, 255, 0.8);
}

.header {
  margin-bottom: 2rem;
}

.locationInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  form {
    width: 100%;
    margin-bottom: 1.25rem;
    width: calc(min(23rem, 100%));
  }

  @media only screen and (max-width: #{$grid__bp-md}px) {
    width: 100%;
  }
}

.goButton {
  margin-top: 4%;
  margin-bottom: 4%;
  width: 100%;
}

.loginSection {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  padding-top: 1.25rem;

  border-top: 2px solid $mono-default-light;
}

.loginHeader {
  margin-bottom: 0.55rem;
  color: $mono-black;
}

.loginButton {
  width: calc(min(23rem, 100%));
}

.postalCode {
  @media only screen and (min-width: 767px) {
    position: relative;
  }
}

.postalCodeButton {
  button {
    height: calc(3rem + 2px);
  }
  @media only screen and (min-width: 767px) {
    position: absolute;
    right: 0;
    top: 0;
  }

  @media only screen and (max-width: 767px) {
    margin-top: 1rem;
  }
}
