@import '../utilities/variables';

.modalCloseSection {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: $mono-white;
  margin-top: 0.75rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
