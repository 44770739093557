@import '../utilities/variables';

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 auto;
  padding: 0 1rem 0rem;
  color: $mono-black;

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    padding: 0 1rem 0 1rem;
  }

  p {
    margin: 1rem 0 1rem 0;
  }
}
.title{
  font-size: 19px;
  font-weight:300;
}
.individualTireWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  gap: $col-margin;
  label {
    width: 100%;
    font-size: 14px;
    // remove margin bottom due to flex gap above
    margin-bottom: 0;
  }
  .title{
    font-size: 19px;
  }
 .individualTireInput {
  // 4 columns by default
  width: calc(50% - #{$col-margin});
  // 2 columns on small screens
  @media only screen and (max-width: #{$grid__bp-md}px) {
    width: calc(50% - #{$col-margin});
  }
 } 
 
 .additionalInfo{
  width: calc(50% - #{$col-margin});
}
 .addVehicleButton{
  margin-top: -2%;
  margin-bottom: 2%;
  width: calc(50% - #{$col-margin});

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    width: calc(50% - #{$col-margin});
  }
}
}
.editButtonVehicle {
  min-width: 70%;
  margin-top: -5%;
  margin-bottom: 3%;
  @media only screen and (max-width: #{$grid__bp-xl}px) {
    width: 100%;
  }
}
.addVehicleButton{
  margin-top: -2%;
  margin-bottom: 2%;
  width: calc(40% - #{$col-margin});

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    width: calc(40% - #{$col-margin});
  }
}
.editButton {
  min-width: 70%;
  margin-top: 2%;
  @media only screen and (max-width: #{$grid__bp-xl}px) {
    width: 100%;
  }
}
.editButtonInline{
  margin-top: 2%;
  min-width: 70%;
  @media only screen and (max-width: #{$grid__bp-xl}px) {
    width: 100%;
  }
}

.input_box {
  width: 70%;

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    width: 100%;
  }
}