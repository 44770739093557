@import '../utilities/variables';

.row {
  padding: 1.25rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1.5rem;
    flex-direction: column;
    align-items: stretch;
  }
}

.rowContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.subRow {
  display: flex;
  position: relative;
  width: 100%;

  @media only screen and (min-width: #{$grid__bp-lg}px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    flex-direction: column;
  }
}

.form {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
  margin-top: 16px;
  display: flex;
}

.buttons {
  display: flex;
  gap: 0.5rem;
  align-self: end;

  @media only screen and (min-width: #{$grid__bp-lg}px) {
    flex-direction: row;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-top: 1rem;
    align-self: stretch;
    button {
      flex-grow: 1;
    }
  }
}

.input {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    width: 47%;
  }
  padding-top: 8px;
}
