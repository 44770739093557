@import '../utilities/variables';

.loading {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 50%;
}

.invoiceDetailsContainer {
  display: flex;
  flex-direction: column;
}

.backButtonContainer {
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 1rem;
  background-color: $blue-lightest;
  border-bottom: 2px solid rgba(226, 226, 226, 0.4);
  h5 {
    font-weight: 700;
  }
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1rem;
  }
}

.backButtonContent {
  display: flex;
  align-items: center;
  padding-left: 24px;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-left: 0px;
  }
}

.caret {
  background-color: inherit;
  border: none;
  cursor: pointer;
  border-radius: 24px;
  height: 32px;
  width: 32px;
  transform: rotate(90deg);
  display: flex;
  align-items: flex-end;
  padding-bottom: 0;
}

.payInvoiceButtonContainer {
  display: flex;
  gap: 0.5rem;
  margin: 1.3rem 0;
  padding: 0 1rem;
  button {
    width: 100%;
  }
}

.invoiceButtonContainer {
  display: flex;
  gap: 0.5rem;
  padding: 0 1rem;
  justify-content: flex-start;
  align-items: center;
}

.downloadButton {
  width: auto;
  white-space: nowrap;
  padding: 0 1rem;
}
