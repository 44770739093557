@import '../utilities/variables';

.pastAppts {
  height: 100%;
}

.loading {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 50%;
}