@import "../utilities/variables";

.carViewerBox p {
  padding: 1rem 1.5rem;
  background-color: $mono-lighter;
}

.header {
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  Button {
    min-width: 0;
  }
}
