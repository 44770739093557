.loading {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-left: 1rem;
  }
}

.previousSelector {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
