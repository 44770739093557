@import '../utilities/variables';

.row {
  padding: 1.25rem 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1.5rem;
  }
}
.carIdentifiers {
  margin-bottom: 0.9rem;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    margin-bottom: 0.6rem;
  }
}
.left {
  flex-grow: 1;
}

.right {
  align-self: flex-end;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    align-self: flex-start;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  min-width: 8.75rem;
}

.details {
  font-family: Open Sans;
  font-weight: $font-weight-md;
}

.subtitle {
  color: $mono-darker;
  font-size: 15px;
}

.rowContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}