@import '../utilities/variables';

.carSelector {
  padding: 1.5rem;
  color: $mono-black;
}

.searchBar {
  width: 100%;
  padding: 0.5rem;
  display: flex;
}

.magnifyingGlass {
  display: flex;
  align-items: center;
  background-color: $mono-lighter;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.subtitle {
  font-size: 15px;
  padding: 0 0 1rem 0;
}
.toggleContainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f0f0f0;
}

.toggleContainer button {
  flex: 1;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  background: #f0f0f0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.toggleContainer button.active {
  background-color: #298dc1;
  color: white;
}

.toggleContainer button:not(.active):hover {
  background-color: #d9d9d9; /* Slightly darker shade for hover effect */
}
