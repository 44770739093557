@import '../utilities/variables';

.selectWrapper {
  position: relative;
  margin-top: 0.5rem;
  color: $mono-black;
}

.disabled {
  cursor: not-allowed;
  color: $mono-default-dark;
}
.caret {
  margin-right: 0.75rem;
  color: inherit;
}

.caretWrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  pointer-events: none;
}

.select {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
  width: 100%;

  select {
    background-color: $mono-lighter;
    border: 1px solid transparent;
    padding: 0.75rem 1rem;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';

    &:focus {
      border: 1px solid $mono-black;
      outline: none;
    }
  }
}

.secondary .select {
  width: 50% !important;
  .select{
    width: 50% !important;
  }
}

select::-ms-expand {
  display: none;
}

.errorMessage {
  margin-top: 0.5rem;
  color: $red-dark;
  @media only screen and (max-width: #{$grid__bp-md}px) {
    display: none;
  }
}

.mobileErrorMessage {
  color: $red-dark;
  align-self: flex-start;
  @media only screen and (min-width: #{$grid__bp-md}px) {
    display: none;
  }
}

.error {
  color: $red-dark;
  background-color: $red-light !important;
}
