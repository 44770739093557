@import "../utilities/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  flex: 1 1 1px;
  max-height: 100%;
  overflow: auto;
  width: 252px;
}

.daySectionHeader {
  background-color: $mono-lighter;
  padding: 0.6rem 1rem;
  color: $mono-black;
}

.time {
  padding: 1.125rem 1rem;
  color: $mono-text-black;
  cursor: pointer;

  &.booked {
    background-color: $blue-lighter;
    color: $mono-text-black;
  }

  &:hover {
    background-color: $blue-default;
  }

  &.selected {
  background-color: $blue-default;
}
}
