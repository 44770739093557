@import '../utilities/variables';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    height: auto;
  }
}

.header {
  background-color: $blue-lightest;
  width: 100%;
  display: flex;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 auto;
  padding: 0 1rem 0rem;
  color: $mono-black;

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    padding: 0 1rem 0 1rem;
  }

  p {
    margin: 1rem 0 1rem 0;
  }
}

.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: right;
  padding-top: 30px;
  padding-bottom: 25px;
  @media only screen and (max-width: #{$grid__bp-xl}px) {
    justify-content: space-between;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}

.buttonsContainer_additionalVehicleInfo {
  display: flex;
  width: 100%;
  justify-content: right;
  padding-top: 100px;
  padding-bottom: 25px;
  @media only screen and (max-width: #{$grid__bp-xl}px) {
    justify-content: space-between;
    padding-top: 65%;
    padding-bottom: 1rem;
    padding-top: 80px;
  }
}

.button {
  align-self: flex-end;
  margin: 0 1rem;
  @media only screen and (max-width: #{$grid__bp-xl}px) {
    padding: 1rem 2rem;
    width: 45%;
    margin: 0;
  }
}

.input_box {
  width: 55%;

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    width: 100%;
  }
}

.empty {
  margin: 0.75rem;
  padding: 2rem 0;
  background-color: $blue-lightest;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}