@import '../utilities/variables';

.customerInformation {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    padding: 24px 37px;
  }
}

.header {
  padding: 0;
  padding-bottom: 0.6rem;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1.5rem;
  }
}

.section {
  padding-bottom: 32px;
}

.backButtonContainer {
  padding: 1.5rem 2rem 1.5rem 1rem;
  h5 {
    font-weight: 700;
    margin-right: auto;
  }
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1rem;
  }
}

.backButtonContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-left: 0px;
  }
}

.caret {
  background-color: inherit;
  border: none;
  cursor: pointer;
  border-radius: $border-radius-lg;
  height: 28px;
  width: 32px;
  transform: rotate(90deg);
  display: flex;
  padding-bottom: 0;
}