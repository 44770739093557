@import '../utilities/variables';

.rowContainer {
  border: 2px solid $mono-light-grey;
  display: flex;
  flex-direction: column;
}

.descriptionContainer {
  display: flex;
  padding: $container-padding-md;
  flex-direction: row;
  justify-content: space-between;
  color: $mono-black;
  align-items: center;
}

.mainContent {
  padding-left: $container-padding-lg;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-left: 0px;
  }
}

.caret {
  background-color: inherit;
  border: none;
  cursor: pointer;
  border-radius: $border-radius-lg;
  height: 48px;
  width: 48px;
  transform: rotate(270deg);
}

.subtitle {
  color: $mono-darker;
  font-size: 15px;
}