@import '../utilities/variables';

.accountPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  background-color: $blue-lightest;
  overflow-y: scroll;

  padding: 3rem 4rem;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 0;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  button svg {
    display: none;
  }
  
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1.5rem;
    padding-bottom: 0;
  }
}

.editButton {
  width: 8.75rem;
}

.leftHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.headerDesktop,
.desktopHeaderActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.headerMobile {
  display: inline-block;
  vertical-align: middle;
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.mobileHeaderActions {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.dropdownItem {
  width: 100%;
  padding: 0.75rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: $blue-lightest;
  }

  button {
    border: none;
    background-color: inherit;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: left;
    cursor: pointer;
  }
}
