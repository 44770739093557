@import '../utilities/variables';

.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: right;
  padding-top: $container-padding-lg;
  padding-bottom: 25px;
  @media only screen and (max-width: #{$grid__bp-xl}px) {
    justify-content: space-between;
    padding-bottom: 1rem;
    padding-top: 3rem;
  }
}

.button {
  align-self: flex-end;
  margin: 0 1rem;
  @media only screen and (max-width: #{$grid__bp-xl}px) {
    padding: 1rem 2rem;
    width: 45%;
    margin: 0;
  }
}