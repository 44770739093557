@import "../utilities/variables";

.box {
    display: block;
    width: 100%;
    p {
        margin-bottom: 0.5rem;
        color: $mono-black;
      }
    margin-bottom: 1rem;
}