@import '../utilities/variables';

.modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    height: auto;
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 auto;
  padding: 0 1rem 1rem;
  color: $mono-black;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    padding: 1rem;
  }

  p {
    margin: 1rem 0 2rem 0;
  }
}

.vanList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.vanCardContainer {
  width: 100%;
}

.vanCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;

  &.selectedRow {
    border-color: #007bff;
  }
}

.vanCardHeader {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.vanName {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.expandButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.arrowIcon {
  transition: transform 0.3s ease-in-out;

  &.expandedArrow {
    transform: rotate(180deg);
  }
}

.expandedInfoContainer {
  background-color: $mono-lighter;
  padding: 16px;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 8px 8px;
  margin-top: -1px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    font-size: 0.875rem;
    line-height: 1.2;
    margin: 0.25rem 0;
  }
}

.detailsCopy {
  white-space: pre-wrap;
}

.conflicts {
  margin-top: 8px;

  .conflictBadge {
    margin-right: 4px;
  }
}

.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.cancelButton {
  margin-right: 16px;
}

.confirmButton {
  margin-left: 16px;
}
