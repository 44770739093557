@import '../utilities/variables';

.upcomingAppts {
  height: 100%;
}

.loading {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 50%;
}

.buttonWrapper {
  padding: 12px;
}

.bookButton {
  width: 164px;
}
