@import '../utilities/variables';

.customersInformation {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    padding: 24px 37px;
  }
}

.searchBar {
  padding-bottom: 1rem;
  width: 45%;
  display: flex;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 12px 18px;
    width: 100%;
  }
}
.headerContainer {
  padding-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 12px 18px;
    width: 100%;
  }
}
.addCustomer {
  display: flex;
  justify-content: flex-end;
  min-width: 8.75rem;
}

.magnifyingGlass {
  display: flex;
  align-items: center;
  background-color: $mono-lighter;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}