@import '../utilities/variables';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(min(100%, 20rem));
  gap: 0.5rem;

  border: none;
  background-color: inherit;
  padding-left: 0;
  padding-bottom: 1.75rem;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    flex-direction: column;
    width: 100%;
  }
}

.desktop {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.mobile {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.dateInput {
  flex-shrink: 4;
}

.dateInput,
.timeInput {
  width: auto;
  align-items: flex-start;
  align-self: flex-start;
  margin-top: 0.5rem;
}

.dateAndTimeSection {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  max-height: 320px;
}
