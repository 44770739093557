@import '../utilities/variables';

.completedWorkOrders {
  height: 100%;
}

.loading {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 50%;
}

.date {
  padding: 23.5px 16px;
  font-family: Open Sans;
  font-weight: $font-weight-md;
  color: $mono-black;
}

.loadMoreButtonContainer {
  padding: 10px;
}

.loadMoreButton {
  width: 100%;
}

.searchDiv {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    padding: 0 37px 24px 37px;
  }
}

.searchBar {
  width: 100%;
  padding: 0.5rem;
  display: flex;
}

.magnifyingGlass {
  display: flex;
  align-items: center;
  background-color: $mono-lighter;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.subtitle {
  color: $mono-darker;
  font-size: 15px;
  padding: 1.5rem 1.5rem 0 1.5rem;
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    padding: 24px 45px 0 45px;
  }
}
