@import '../utilities/variables';

.price_total {
  font-weight: 600;
  font-family: Open Sans;
  font-size: 16px;
}

.title {
  font-family: Montserrat;
  font-weight: 700;
  size: 16px;
  line-height: 40px;
}

.container {
  padding: 0 1rem 0rem;
  @media only screen and (max-width: #{$grid__bp-xl}px) {
    padding: 0 1rem 0rem;
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 1rem;
  color: $mono-black;
  p {
    margin: 1rem 0;
  }
}

.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 2rem 1rem 3rem;
  background-color: #F0F5F8;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0rem rem;
}

.lineItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;

  &:last-child {
    margin-bottom: 1rem;
    border-bottom: 1px solid #BDBDBD;
  }
}

.price {
  color: #828282;
}

.lineItem_total {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;

  &:last-child {
    margin-bottom: 1rem;
  }
}
