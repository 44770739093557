@import '../utilities/variables';

.yourInformation {
  height: calc(100% - 57px);
}

.tabs {
  position: relative;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: row;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50%;
  height: 57px;
  background-color: $mono-white;
  cursor: pointer;

  /* Label */
  font-family: Open Sans;
  font-weight: $font-weight-md;
  font-size: 16px;
  color: $mono-black;
  /* Border */
  border: none;
  border-bottom: 4px solid #f2f2f2;
}

.desktopTab {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.mobileTab {
  font-size: 14px;
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.tabSelected {
  border-bottom: 4px solid black;
}

.content {
  height: 100%;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    min-height: 219px;
  }
}
