@import '../utilities/variables';

.default_discount_input,
.invoice_discount_input {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: #{$grid__bp-xl}px) {
    display: block; 
  }

  .discount_title {
    margin-top: 8px;
  }

  .discount_applied {
    font-weight: 600;
    font-family: Open Sans;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
    margin-left: 40%;
    white-space: nowrap;

    @media screen and (max-width: #{$grid__bp-xl}px) {
      margin-top: -25px;
      margin-right: 0px;
      margin-left: 0px;
      white-space: nowrap;
      position: absolute;
      padding-right: 1rem;
      right: 0;
    }
  }

  .discount_setting {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 165px;

    @media screen and (min-width: #{$grid__bp-xl}px) {
      margin-left: 30%;
    }

    @media screen and (max-width: #{$grid__bp-xl}px) {
      width: 100%;
    }
    
    .discount_input_box {
      height: 40px;
      max-width: 79px;
      min-width: 79px;
      border: 1px solid #C5C5C5;

      input {
        padding: 0;
        line-height: 36px;
        padding-left: 1rem;
      }

      @media screen and (max-width: #{$grid__bp-xl}px) {
        margin-left: 0;
        max-width: 80%;
      }
    }
    
    .discount_type {
      color: $blue-darkest;
      background-color: $mono-white;
      border: 1px solid $mono-default-dark;
      align-self: flex-end;
      min-width: 48px;
      width: 48px;
      height: 40px;
      border-radius: 0;

      @media screen and (max-width: #{$grid__bp-xl}px) {
        width:25%;
      }
    
      &:hover {
        background-color: $blue-lightest;
      }
    
      &:focus, &.active {
        background-color: $blue-default;
        color: white;
      }
    
      &:active {
        background-color: $blue-darker;
        border-color: $blue-default;
      }
    }
  }

  .apply_remove_discount {
    align-self: flex-end;
    flex-direction: row;
    margin-left: 1rem;
    white-space: nowrap;

    @media screen and (max-width: #{$grid__bp-xl}px) {
      margin-left: 0;
      width: 100%;
      margin-top: 1rem;
      
    }
  }
}

.invoice_discount_input {
  justify-content: space-between;
  width: 100%;
}

.default_discount_input {
  justify-content: flex-start;
  border-top: 1px solid $mono-default-dark;
  padding-top: 1rem;

  @media screen and (max-width: #{$grid__bp-xl}px) {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
  }

  .discount_setting {
    @media screen and (min-width: #{$grid__bp-xl}px) {
      margin-left: 0px;
    }

    @media screen and (max-width: #{$grid__bp-xl}px) {
      width: 100%;
    }
  }

  .discount_applied {
    margin-left: 0px;

    @media screen and (max-width: #{$grid__bp-xl}px) {
      position: static;
      white-space: nowrap;
    }
  }
}
