@import '../utilities/variables';

.row {
  padding: 1.25rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1.5rem;
  }
}
.rowHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
}

.rowText {
  font-size: 1.1rem;
  font-weight: $font-weight-md;
  flex-grow: 1;
}

.viewSubscriptionsButton {
  margin-left: auto;
  margin-top: 0.5rem;
}

.left {
  flex-grow: 1;
}

.right {
  align-self: flex-end;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    align-self: flex-start;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  min-width: 2rem;
}

.details {
  font-family: Open Sans;
  font-weight: $font-weight-md;
}

.subtitle {
  color: $mono-darker;
  font-size: 15px;
}

.rowContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.identifierContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    flex-direction: column;
  }
}

.carIdentifiers {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 250px;
}

.textInput {
  width: 100%;
  max-width: 300px;
}

.setButton {
  margin-top: 0.5rem;
  max-width: fit-content;
  white-space: nowrap;
  padding: 0.5rem 2rem;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
  width: 100%;
}
