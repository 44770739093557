@import "../utilities/variables";

.empty {
  margin: 0.75rem;
  padding: 2rem 0;
  background-color: $blue-lightest;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.editButton {
  min-width: 8.75rem;
}

.error {
  flex-direction: column;
}