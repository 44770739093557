@import '../utilities/variables';

.detailsRowContainer {
  display: flex;
  padding: 16px;
  flex-direction: row;
  justify-content: space-between;
  color: $mono-black;
  align-items: center;
  border-bottom: 2px solid rgba(226, 226, 226, 0.4);
}

.content {
  padding-left: 32px;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-left: 0px;
  }
}

.italicText {
  font-style: italic;
}