@import '../utilities/variables';

body {
  color: $mono-text-black;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
}

h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 52px;
}

h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 52px;
}

h4 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
}

h5 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

p,
input,
textarea,
select {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.label,
label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: $font-weight-md;
  font-size: 16px;
  line-height: 24px;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: $font-weight-md;
    font-size: 14px;
    line-height: 20px;
  }
}

.smallLabel {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: $font-weight-md;
  font-size: 14px;
  line-height: 20px;
}
