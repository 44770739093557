@import '../utilities/variables';

.services {
  min-height: 100%;
  flex: 1 1 0px;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    flex-basis: auto;
  }
}

.editButton {
  min-width: 8.75rem;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 4rem;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 0.75rem 1.5rem;
  }
}

.headerDesktop {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.headerMobile {
  display: inline-block;
  vertical-align: middle;
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.body {
  padding: 0.75rem 4rem;
  padding-bottom: 0;
  flex: 1 1 1px;
  overflow: hidden;
  max-height: 100%;
  min-height: 0;
  display: flex;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    flex-direction: column;
    overflow: auto;
    padding: 0;
  }
}

.bodyLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: calc(min(25rem, 100%));
  margin-right: 2.5rem;
  padding-right: 0.75rem;
  gap: 2rem;

  @media only screen and (min-width: #{$grid__bp-lg}px) {
    max-width: 400px;
    overflow: auto;
    min-height: 0px;
  }

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
  }
}

.selectedServicesBox {
  width: 100%;
  margin-bottom: 1rem;
}

.carSelectorBox {
  background-color: $mono-white;
  box-shadow: $box-shadow;
  width: 100%;

  @media only screen and (min-width: #{$grid__bp-lg}px) {
    box-shadow: $box-shadow;
    margin-bottom: 0;
  }

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    box-shadow: none;
    flex: 1 1 1px;
  }
}

.bodyRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: none;
  margin-bottom: 1rem;

  @media only screen and (min-width: #{$grid__bp-lg}px) {
    max-width: 860px;
  }

  @media only screen and (min-width: #{$grid__bp-lg}px) {
    overflow: auto;
    flex: 1 1 1px;
    max-height: 100%;
    min-height: 0px;
    margin-bottom: 0;
  }
}

.footer {
  box-shadow: -5px -11px 16px 4px rgba(0, 0, 0, 0.08);
  background-color: $mono-white;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    background-color: $blue-lightest;
  }
}

.countBadge {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: none;
  }
}
