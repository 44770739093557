@import '../utilities/variables';

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 auto;
  padding: 0 1rem 0rem;
  color: $mono-black;
  max-height: 50vh;

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    max-height: 80vh;

  }
}

.headerTitle {
  margin-top: .5rem;
  margin-bottom: 1rem;
}

.selectSubscriptionContainer {
  margin-left: .5rem;
  overflow-y: auto;
  width: 100%;

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    padding-right: 1rem;
  }
}

.radioLabel label::before {
  margin-right: 1rem;
}

.activeSubscriptionSubTitle {
  font-weight: 700;
  margin-bottom: .5rem;
}

.textLine {
  margin-left: 2.6rem;
}

.activeSubscriptionList {
  margin-left: 1rem;
}

.activeSubscriptionNameContainer {
  display: flex;
}

.activeSubscriptionName {
  margin-left: 1rem;
  font-weight: 700;
  margin-bottom: .5rem;
}

.activeSubscriptionDescription {
  margin-left: 2.5rem;
}

.subscriptionItemContainer {
  margin-top: 1rem;
}

.selectSubscriptionContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subscriptionItemContainer {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 15px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
}
