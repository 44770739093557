@import "../utilities/variables";

.radioItem {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radioItem_subtitle {
  position: relative;
  padding: 0 25px;
  margin: 1px 0 0;
  color: $mono-darker;
  font-size: 15px;
}

.radioItem input[type='radio'] {
  display: none;
}

.radioItem label {
  color: $mono-text-black;
  font-weight: normal;
}

.radioItem label:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid $mono-text-black;
  background-color: transparent;
}

.radioItem input[type=radio]:checked + label:after {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 10px;
  left: 11px;
  content: " ";
  display: block;
  background: $mono-text-black;
}

.radioItem input[type=radio]:checked + label {
  font-weight: 700;
}
