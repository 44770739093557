@import '../utilities/variables';

.loading {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 50%;
}

.workOrderDetailsContainer {
  display: flex;
  flex-direction: column;
}

.editButton {
  margin-left: auto;
  min-width: 8.75rem;
  margin-right: 2.5rem;
}
.downloadButton2 {
  margin-right: auto;
  min-width: 8.75rem;
}

.content {
  padding-left: $container-padding-lg;
  flex-direction: row;
  display: flex;
  gap: 2em;
  margin-bottom: 10px;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-left: 0px;
  }
}

.unavailabilityReasonBadge {
  margin-left: 1rem;
}

.saveCancelButton {
  margin: 0.2rem;
}

.editScheduledAtTitle {
  padding-left: 48px;
  padding-top: 16px;
  min-width: 10rem;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-left: 16px;
  }
}

.editScheduledAt {
  flex-wrap: wrap;
  margin-right: auto;
}


.backButtonContainer {
  padding: 0.625rem 1rem;
  border-bottom: 2px solid $mono-light-grey;
  h5 {
    font-weight: 700;
  }
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1rem;
  }
}

.backButtonContent {
  display: flex;
  align-items: center;
  padding-left: 24px;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-left: 0px;
  }
}

.caret {
  background-color: inherit;
  border: none;
  cursor: pointer;
  border-radius: $border-radius-lg;
  height: 32px;
  width: 32px;
  transform: rotate(90deg);
  display: flex;
  align-items: flex-end;
  padding-bottom: 0;
}

.completeWorkOrderButtonContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1.3rem 0;
  padding: 0 1rem;
  button {
    width: 100%;
  }
}

.deleteButton {
  margin-left: auto;
}
.assignButton {
  width: 30%;
  margin-left: 35%;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    margin-left: auto;
  }
}
.tableContainer {
    margin-top: 1%;
    width: 80%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid lightgrey;
    margin-bottom: 2%;
  }
.tableContainer2 {
  margin-top: 1%;
  width: 95%;
  margin: 0 auto;
  border-collapse: collapse;
  border: 1px solid lightgrey;
  margin-bottom: 2%;
}

.tableCellName {
  border: 1px solid lightgrey;
  padding: 8px;
}
  
.tableHeader,
.tableCell {
border: 1px solid lightgrey;
padding: 8px;
text-align: center;
}

.tableHeaderBold,
.tableCellBold {
  font-weight: bold;
  border: 1px solid lightgrey;
  padding: 8px;
  text-align: center;
  font-size: 14px;
}
.tableHeader2,
.tableCell2 {
border: 1px solid lightgrey;
padding: 8px;
text-align: center;
font-size: 14px;
}

.tableLastRow {
font-weight: bold;
border-top: 2px solid darkgrey;
}

.tableLastRowName {
border-top: 2px solid darkgrey;
padding: 8px;
text-align: center;
}

.tableLastRowValue {
border-top: 2px solid darkgrey;
padding: 8px;
text-align: center;
}
.downloadButton {
    margin-left: auto;
  }

.detailsButton {
  font-size: 16px;
  width: 168px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.detailsToggleContainer {
  margin-left: 3%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.detailsToggleContainer span {
  margin-right: 8px;
}

.caretToggle {
  transition: transform 0.3s ease;
  transform: rotate(0deg);
  &.expanded {
    transform: rotate(180deg);
  }
}
.paymentDetails{
  flex-direction: column;
  margin-left: 4%;
}

.subtitle {
  color: $mono-darker;
  font-size: 15px;
  margin-left: 3%;
}
