@import '../utilities/variables';

.date {
  padding: 23.5px 16px;
  font-family: Open Sans;
  font-weight: $font-weight-md;
  color: $mono-black;
}

.apptDetails {
  border: 2px solid $mono-light-grey;
  display: flex;
  flex-direction: column;
}

.condensedDetails {
  display: flex;
  padding: $container-padding-md;
  color: $mono-black;
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    flex-direction: row;
  }

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    flex-direction: column;
  }
}

.caret {
  background-color: inherit;
  border: none;
  cursor: pointer;
  border-radius: $border-radius-lg;
  height: 48px;
  width: 48px;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.openCaret {
  transform: rotate(180deg);
  background-color: $blue-lightest;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.mainContent {
  padding-left: $container-padding-lg;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-left: 0px;
  }
}

.cancelButton {
  font-family: Open Sans;
  font-weight: $font-weight-md;
  background-color: transparent;
  text-decoration: underline;
  color: $red-dark;
  border: none;
  cursor: pointer;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    margin-left: 16px;
  }
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    margin-left: auto;
    margin-right: 0px;
  }
}

.receiptButton {
  font-family: Open Sans;
  font-weight: $font-weight-md;
  background-color: transparent;
  text-decoration: underline;
  color: $blue-darkest;
  border: none;
  cursor: pointer;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    margin-left: 16px;
  }
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    margin-left: auto;
    margin-right: 0px;
  }
}

.detailsButton {
  font-size: 16px;
  width: 168px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.buttons {
  display: flex;
  align-items: center;
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    margin-left: auto;
    margin-right: 0px;
  }
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-top: 16px;
  }
}

.totalRow {
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  color: $mono-black;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    background-color: $mono-lighter;
    margin: $row-margin;
  }
}

.totalLabel {
  font-weight: $font-weight-md;
}

.price {
  margin-left: auto;
  margin-right: 0;
  font-weight: $font-weight-md;
}

.br {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.includesBox {
  padding: 16px 24px;
  background-color: $mono-lighter;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    margin: $row-margin;
  }
}

.includesLabel {
  font-weight: $font-weight-md;
  font-size: 14px;
  color: #4f4f4f;
}

.items {
  list-style-type: disc;
  list-style-position: inside;
  font-family: Open Sans;
  color: $mono-black;
  padding-left: 16px;
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    background-color: $mono-white;
    padding: 16px 24px;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}
.saveCancelButton {
  margin: 0.2rem;
}

.editScheduledAtTitle {
  padding-left: 48px;
  padding-top: 16px;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-left: 16px;
  }
}

.editScheduledAt {
  flex-wrap: wrap;
  margin-right: auto;
}
