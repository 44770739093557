@import '../utilities/variables';

.customerInformation {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    padding: 24px 37px;
  }
}
.header {
  padding: 0;
  padding-bottom: 0.6rem;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1.5rem;
  }
}

.contacts {
  padding-bottom: 32px;
}

.paymentHeader {
  border-bottom: 1px solid;
  border-color: #c0c7d1;
  padding: 0;
  padding-bottom: 0.6rem;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1.5rem;
  }
}
.completedWorkOrders {
  height: 100%;
}

.loading {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 50%;
}

.date {
  padding: 23.5px 16px;
  font-family: Open Sans;
  font-weight: $font-weight-md;
  color: $mono-black;
}

.loadMoreButtonContainer {
  padding: 10px;
}

.loadMoreButton {
  width: 100%;
}