@import "../utilities/variables";

.dropdownTrigger {
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  width: 13rem;
  background-color: $mono-white;
}
