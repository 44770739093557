@import "../utilities/variables";

.tabIcon {
  margin-right: 0.75rem;
}

.services {
  display: flex;
  flex-direction: column;
  min-height: 0px;
  width: 100%;
  background-color: $mono-white;
  box-shadow: $box-shadow;
}

.loading {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.scrollable {
  overflow: auto;
  width: 100%;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
