@import "../utilities/variables";

.spinnerWrapper {
  text-align: center;
  width: 12px;
}

.spinner {
  display: inline-block;
  border-radius: 50%;
  animation: spin 2s linear infinite;

  &:before,
  &:after {
    content: "";
    border-radius: 50%;
    background: $blue-default;
    position: absolute;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  border: 1px solid $blue-lightest;
  border-top: 1px solid $blue-default;
  width: 10px;
  height: 10px;
}
