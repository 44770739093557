@import '../utilities/variables';

.userInfo {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    padding: 24px 37px;
  }
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 24px;
  }
}

.header {
  display: flex;
  justify-content: space-between;

  button svg {
    display: none;
  }

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    button {
      border: none;
      min-width: 0;

      span {
        display: none;
      }

      svg {
        display: unset;
      }
    }
  }
}

.wrapper {
  overflow-x: auto;
}

.form label {
  margin-top: 1rem;
}

.formInput {
  max-width: 50%;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    max-width: none;
  }
}

.footer {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 2.25rem;
  column-gap: 0.5rem;
  justify-content: flex-end;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    button {
      flex-grow: 1;
    }
  }
}

.table {
  th {
    text-align: left;
    padding: 1rem 1rem 0 0;
    font-weight: bold;
    font-size: 0.9em;
  }
}

.loader {
  width: auto;
  min-height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: flex;
    flex-direction: column;
  }
}
.contacts {
  padding-bottom: 32px;
}

.customerInformation {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    padding: 24px 37px;
  }
}

.header {
  padding: 0;
  padding-bottom: 0.6rem;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1.5rem;
  }
}

.notificationBox {
  display: flex;
  align-items: center;
}
.checkBox {
  margin-left: 2%;
}