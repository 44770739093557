@import '../utilities/variables';

.modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    max-height: 100vh;
    overflow: scroll;
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 auto;
  padding: 0 1rem 1rem;
  color: $mono-black;
  @media only screen and (max-width: #{$grid__bp-md}px) {
    padding: 1rem;
  }

  form {
    margin-top: 1rem;
    width: 100%;
    @media only screen and (max-width: #{$grid__bp-md}px) {
      margin-top: .5rem;
    }
  }
}

.buttonsContainer {
  margin-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: right;
  @media only screen and (max-width: #{$grid__bp-md}px) {
    justify-content: space-between;
    margin-top: 1rem;
  }
}

.button {
  margin: 0 1rem;
  @media only screen and (max-width: #{$grid__bp-md}px) {
    padding: 1rem 2rem;
    width: 45%;
    margin: 0;
  }
}

.expireDateContainer {
  display: flex;
  justify-content: space-between;
  label {
    width: 48%;
  }
}
