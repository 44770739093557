@import '../utilities/variables';

.modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    height: auto;
  }
}

.header {
  background-color: $blue-lightest;
  width: 100%;
  display: flex;

  @media only screen and (min-width: #{$grid__bp-md}px) {
    display: none;
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 auto;
  padding: 1rem;
  padding-top: 0;

  color: $mono-black;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    padding-top: 1rem;
  }
  p {
    margin-bottom: 3.5rem;
  }
}

.button {
  align-self: flex-end;
}
