@import '../utilities/variables';

.navBar {
  background-color: $blue-logo;
  display: flex;
  width: 100%;
}
.navBarClosed {
  margin: 0;
  flex-direction: row;
  position: relative;
  align-items: center;
}

.navBarOpen {
  flex-direction: column;
  position: fixed;
  height: 100%;
  z-index: 4;
}

.logo {
  height: 82px;
  width: 16rem;
  display: flex;
  padding-left: 4rem;
  align-items: center;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-left: 1.5rem;
  }
}

.logoSvg {
  // max-width: 100%;
  // height: auto;
  // object-fit: contain;
  height: 35px;
}

.navLinks {
  display: flex;
  font-family: Montserrat;
  font-weight: bold;
}

.navLinksClosed {
  flex-direction: row;
  align-items: center;
  gap: 4rem;
  position: absolute;
  right: 4rem;
  font-size: 12px;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    right: 2rem;
  }
}

.navLinksOpen {
  flex-direction: column;
  padding-top: 40px;
  font-size: 24px;
  text-align: center;
}

.link {
  color: $mono-text-black;
  &:hover {
    color: $mono-white;
  }
}
.linkClosed {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.linkOpen {
  padding: 20px;
}

.currentPage {
  text-decoration: underline;
}

.button {
  background: none;
  border: none;
  &:hover {
    color: $mono-white;
  }
}

.menuButton {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.closeButton {
  position: absolute;
  right: 2rem;
  top: 26px;
}

.dropdownContainer {
  position: relative;
  display: inline-block;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    width: 100%;
    text-align: center;
  }
}


.dropdownTrigger {
  background-color: #298dc1;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.2s ease;
  width: auto;
  text-align: left;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.469);
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    font-size: 10px;
    padding: 1px 1px;
    text-align: center;
    width: 100%;
    color: #000000;
  }
}

.dropdownTrigger:hover {
  background-color: #298dc1;
}

.dropdownTrigger:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.75);
}

.dropdownList {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-top: 5px;
  padding: 5px 0;
  list-style: none;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  max-height: 50vh;
  overflow-y: auto;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    position: relative;
    top: 0;
    margin-top: 0;
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: #298dc1;
  }
}

.dropdownItem {
  padding: 12px 20px;
  background-color: #ffffff;
  color: #333;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  display: block;
  transition: background-color 0.3s ease, color 0.2s ease, transform 0.2s ease;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    font-size: 15px; /* Reduce font size for mobile */
    font-weight: 500;
    padding: 10px 15px; /* Adjust padding for smaller touch targets */
    text-align: center; /* Center align items for mobile */
    background-color: #298dc1;
  }
}

.dropdownItem:hover {
  background-color: #f0f0f0;
  color: #000;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    background-color: #eaf4ff; /* Slight blue for hover in mobile */
  }
}

.dropdownItem:focus {
  background-color: #e6f7ff;
  outline: none;
}
