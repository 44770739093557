@import "../utilities/variables";

.tabContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  color: $mono-darker;
}

.tab {
  height: 3.25rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  border-bottom: 3px solid $mono-lighter;
  cursor: pointer;
  padding-bottom: 0.875rem;
}

.step {
  border-top: 3px solid $mono-lighter;
  height: 3.25rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  padding-top: 0.875rem;
}

.selected {
  border-color: $mono-darkest;
  color: $mono-black;
}

.disabled {
  cursor: not-allowed;
}

.presentational {
  justify-content: flex-start;
  color: $mono-black;
  padding: 1.25rem 1rem;
  height: auto;
  cursor: default;
}
