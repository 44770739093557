@import '../utilities/variables';

.link {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;

  svg {
    @media only screen and (max-width: #{$grid__bp-lg}px) {
      height: 0.7em;
    }
  }
}

.main {
  background-color: $blue-lightest;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.doNotIncludeMobile {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.includeMobile {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.tabText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.selectedTab {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: flex;
  }
}

.selected {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: flex;
  }
}

.disabledLink {
  pointer-events: none;
  cursor: not-allowed;
}
