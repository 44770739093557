@import '../utilities/variables';

.modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    height: auto;
  }
}

.body {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 auto;
  padding: 0 1rem 1rem;
  color: $mono-black;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    padding: 1rem;
  }

  p {
    margin: 1rem 0 1rem 0;
  }
}

.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.button {
  align-self: flex-end;
  margin: 0 1rem;
  @media only screen and (max-width: #{$grid__bp-md}px) {
    padding: 1.8rem 2rem;
  }
}

.toggleButton {
  margin: 1rem 0;
}

.columns {
  display: flex;
  gap: 1.5rem;
  width: 100%;
}

.column {
  flex: 1;
  width: 100%;
}
