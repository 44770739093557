@import "../utilities/variables";

.badge {
  height: 1.25rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  width: auto;
  border-radius: 4px;
  padding: 0 0.5rem;
  cursor: default;
}

.primary {
  color: $mono-white;
  background-color: $blue-default;
}

.secondary {
  color: $mono-darkest;
  background-color: $mono-lighter;
}
