@import '../utilities/variables';

.body {
  padding: 0 1.25rem 1.25rem 1.25rem;
  color: $mono-black;
  @media only screen and (max-width: #{$grid__bp-md}px) {
    padding-top: 1.25rem;
  }

  form {
    margin-top: 1.5rem;
  }
}

.formElement {
  flex-basis: calc(50% - 1rem);
  margin-bottom: 0.5rem;
}

.header {
  background-color: $blue-lightest;
  padding: 0.75rem;
  display: flex;

  @media only screen and (min-width: #{$grid__bp-md}px) {
    display: none;
  }
}

.upperForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media only screen and (max-width: #{$grid__bp-md}px) {
    flex-direction: column;
  }
}

.halfWidthFormElements {
  flex: 1;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: #{$grid__bp-md}px) {
    &:first-child {
      margin-right: 1rem;
    }
  }
}

.actionButtons {
  margin-top: 1.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.tryAnotherButton {
  @media only screen and (max-width: #{$grid__bp-md}px) {
    display: none;
  }
}

.submitButton {
  @media only screen and (max-width: #{$grid__bp-md}px) {
    width: 100%;
  }
}
