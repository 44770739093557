@import '../utilities/variables';

.selectWrapper {
  position: relative;
}

.select {
  display: flex;
  margin-bottom: 1rem;
  margin-bottom: 0;
  flex-direction: column;
  width: 100%;
  select {
    background-color: $mono-lighter;
    border: 1px solid transparent;
    padding: 0.75rem 1rem;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    @media only screen and (max-width: #{$grid__bp-lg}px) {
      width: 100%;
    }

    &:focus {
      border: 1px solid $mono-black;
      outline: none;
    }

    &::-ms-expand {
      display: none;
    }
  }
}

.disabled {
  cursor: not-allowed;
  color: $mono-default-dark;
}

.caret {
  color: inherit;
}

.caretWrapper {
  position: absolute;
  top: 0;
  right: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  pointer-events: none;
}
