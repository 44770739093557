@import "../utilities/variables";

.cancelContainer {
  float: right;
	bottom: 0;
	margin-bottom: 20px;
	margin-right: 10px;
}

.paymentModalBody {
	padding: 20px;
}

.paymentModalHeader {
	margin-bottom: 10px;
}
