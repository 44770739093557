@import '../utilities/variables';

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 auto;
  padding: 0 1rem 0rem;
  color: $mono-black;

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    padding: 0 1rem 0 1rem;
  }

  p {
    margin: 1rem 0 1rem 0;
  }
  .captionText{
    font-size: 16px;
    font-weight: 300;
  }
  .individualTireWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    gap: $col-margin;
    label {
      width: 100%;
      font-size: 14px;
      // remove margin bottom due to flex gap above
      margin-bottom: 0;
    }
    .title{
      font-size: 19px;
    }
   .individualTireInput {
    // 4 columns by default
    width: calc(25% - #{$col-margin});
    // 2 columns on small screens
    @media only screen and (max-width: #{$grid__bp-md}px) {
      width: calc(50% - #{$col-margin});
    }
   } 
   .individualBoxInput{
     // 3 columns by default
    width: calc(30% - #{$col-margin});
    // 3 columns on small screens
    @media only screen and (max-width: #{$grid__bp-md}px) {
      width: calc(50% - #{$col-margin});
    }
   }
   .individualCeckBox {
    width: calc(25% - #{$col-margin});
    margin-top: -2.5%;
    @media only screen and (max-width: #{$grid__bp-md}px) {
      width: calc(50% - #{$col-margin});
      margin-top: -4%;
    }
   }
   .textstyle{
    text-align: left;
    margin-bottom: -0.5%;
   }
   .additionalInfo{
    width: calc(50% - #{$col-margin});
  }
   .textContainer {
    // margin-bottom: 5px;
    display: block;
    padding: 2px;
    height: 70px;
  }
  
  .checkboxContainer {
    padding: 5px;
  }
  }
  .editButtonVehicle {
    min-width: calc(50% - #{$col-margin});
    margin-top: -5%;
    margin-bottom: 5%;
    @media only screen and (max-width: #{$grid__bp-xl}px) {
      width: 100%;
    }
  }
  .addServiceImage{
    margin-top: -5%;
    min-width: calc(70% - #{$col-margin});
    margin-bottom: 2%;
    @media only screen and (max-width: #{$grid__bp-xl}px) {
      width: 100%;
    }
  }
  .addVehicleButton{
    margin-top: -2%;
    margin-bottom: 2%;
    width: calc(40% - #{$col-margin});
  
    @media only screen and (max-width: #{$grid__bp-xl}px) {
      width: calc(40% - #{$col-margin});
    }
  }
  .editButtonInline{
    margin-top: 4%;
    min-width: 20%;
    height: calc(3rem + 2px);
    @media only screen and (max-width: #{$grid__bp-xl}px) {
      margin-top: 6%;
      width: 50%;
    }
  }
  .editButtonInline2{
    margin-top: 2%;
    min-width: 70%;
    @media only screen and (max-width: #{$grid__bp-xl}px) {
      width: 100%;
    }
  }
}