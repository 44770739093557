@import '../utilities/variables';

.textInputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    flex-direction: column;
    gap: 0.75rem;

    button {
      width: 100%;
    }
  }
}

textarea {
  background-color: $mono-lighter;
  border: 1px solid transparent;
  padding: 0.75rem 1rem;
  width: 100%;
  &:active,
  &:focus {
    border: 1px solid $mono-black;
    outline: none;
  }
}

.disabled {
  color: $mono-default-dark;
}

.inputDisabled {
  cursor: not-allowed;
  &:active,
  &:focus {
    border: 1px solid transparent;
    outline: none;
  }
}

label {
  color: $mono-black;
  margin-bottom: 0.5rem;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: $font-weight-md;
    font-size: 14px;
    line-height: 20px;
  }
}
