@import '../utilities/variables';

.vehicleInformation {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    padding: 24px 37px;
  }
}

.header {
  padding: 0;
  padding-bottom: 0.6rem;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1.5rem;
  }
}

.header {
  padding-bottom: 20px;
}

.addVehicleButton {
  border: none;
  background-color: inherit;
  cursor: pointer;
  position: relative;
  left: 17px;
  top: 24px;
  font-family: Open Sans;
  font-weight: $font-weight-md;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: $blue-darker;
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    padding-bottom: 27px;
  }
}

.hr {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    position: relative;
    left: -50px;
    width: 200%;
  }
}
