@import '../utilities/variables';

.textInputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    flex-direction: column;
    gap: 0.75rem;

    button {
      width: 100%;
    }
  }

  button {
    height: calc(3rem + 2px);
  }
}

input:not([type='checkbox']),
.inputHasRightIcon {
  flex-direction: row;
}

input {
  background-color: $mono-lighter;
  border: 1px solid transparent;
  padding: 0.75rem 1rem;
  width: 100%;
  &:active,
  &:focus {
    border: 1px solid $mono-black;
    outline: none;
  }
}

input[type='checkbox'] {
  -webkit-appearance: auto;
}

.hasRightButton {
  @media only screen and (min-width: #{$grid__bp-md}px) {
    &:focus {
      border-right: none;
    }
  }
}

.success {
  color: $green-dark;
  background-color: $green-light;
}

.error {
  color: $red-dark;
  background-color: $red-light;
}

.disabled {
  color: $mono-default-dark;
}

.inputDisabled {
  cursor: not-allowed;
  color: $mono-default-dark;
  &:active,
  &:focus {
    border: 1px solid transparent;
    outline: none;
  }
}

.rightIcon {
  display: flex;
  width: auto;
  margin-right: 0.35rem;

  button {
    height: auto;
  }
}

label {
  color: $mono-black;
  margin-bottom: 0.5rem;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: $font-weight-md;
    font-size: 14px;
    line-height: 20px;
  }
}

.hintWrapper {
  color: #757575;
}
