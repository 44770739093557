@import '../utilities/variables';

.modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    height: auto;
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 auto;
  padding: 1rem;
  color: $mono-black;

  p {
    margin-bottom: 1rem;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.subscriptionList {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.subscriptionItem {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid;
  border-radius: 8px;
  background-color: $mono-white;
}

.subscriptionRow {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: #{$grid__bp-md}px) {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 15px;
  }
}

.arrowIcon {
  transition: transform 0.3s ease-in-out;

  &.expandedArrow {
    transform: rotate(180deg);
  }
}

.subscriptionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.subscriptionDetails {
  margin-top: 8px;
  font-size: 0.9rem;
}

.subscriptionActions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;

  @media (min-width: #{$grid__bp-md}px) {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.unavailabilityReasonBadge {
  margin-left: 0;
  min-width: 8.75rem;
  text-align: center;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}
