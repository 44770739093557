@import '../utilities/variables';

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  min-width: calc(min(100%, 25.75rem));

  background-color: $mono-white;
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    box-shadow: $box-shadow;
  }
}

.header {
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  width: 100%;

  h4 {
    padding: 0 0.5rem;
  }
}

.headerLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.expandCollapse {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    display: none;
  }

  transform: rotate(-90deg);
}

.expandedArrow {
  transform: rotate(90deg);
}

.body {
  width: 100%;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.expanded {
  display: flex;
  flex-direction: column;
}
