@import '../utilities/variables';

.itemsList {
  height: 100%;
  padding: 0 1rem;

  @media only screen and (min-width: #{$grid__bp-lg}px) {
    padding: 0 37px;
  }
}

.searchButton, .addButton {
  margin: 10px;
  padding: 0.5rem 1.5rem;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
}

.loading {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 50%;
}

.loadMoreButtonContainer {
  padding: 10px;
}

.loadMoreButton {
  width: 100%;
}

.input {
  margin-top: 5px;
}

.searchDiv {
  width: 100%;
  display: flex;
  align-items: center;
}

.searchBar {
  width: 100%;
  display: flex;
}

.magnifyingGlass {
  display: flex;
  align-items: center;
  background-color: $mono-lighter;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.inventoryTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  td {
    color: #333;
  }

  tbody tr:hover {
    background-color: #f9f9f9;
  }
}

.itemsList, .tableContainer {
  overflow-x: auto !important;
}
