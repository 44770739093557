@import '../utilities/variables';

.schedule {
  min-height: 100%;
  flex: 1 1 0px;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    flex-basis: auto;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 4rem;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 0.75rem 1.5rem;
  }
}

.headerDesktop {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.selectButton {
  min-width: 8.75rem;
}

.headerMobile {
  display: inline-block;
  vertical-align: middle;
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.body {
  padding: 0.75rem 4rem;
  padding-bottom: 0;
  flex: 1 1 1px;
  overflow: hidden;
  min-height: 0;
  display: flex;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    flex-direction: column-reverse;
    overflow: auto;
    padding: 0;
  }
}

.bodyLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media only screen and (min-width: #{$grid__bp-lg}px) {
    overflow: auto;
    flex: 1 1 1px;
    min-width: 26rem;
    margin-left: 2.5rem;
    max-height: 100%;
    min-height: 0px;
    margin-bottom: 0;
    max-width: 860px;
  }

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 0;
    margin-bottom: 1rem;
  }
}

.bodyRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;

  @media only screen and (min-width: #{$grid__bp-lg}px) {
    overflow: auto;
    flex: 1 1 1px;
    min-width: 26rem;
    margin-left: 2.5rem;
    max-height: 100%;
    min-height: 0px;
    margin-bottom: 0;
    max-width: 400px;
  }
}

.serviceLocationForm {
  width: 100%;

  background-color: $mono-white;
  box-shadow: $box-shadow;
  padding: 2rem 1.5rem;

  form {
    width: 100%;
  }
}

.formHeader {
  margin-bottom: 1.2rem;
}

.formSection {
  margin-bottom: 1rem;
}

.formSubsection {
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (min-width: #{$grid__bp-lg}px) {
    margin-right: 5rem;
  }

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    flex-direction: column;
  }
}

.bookAsGuest {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1rem;

  input[type='checkbox'] {
    width: 1rem;
    margin-left: 0;
    margin-right: 1rem;
  }
}

.bookAsVendor {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1rem;

  input[type='checkbox'] {
    width: 1rem;
    margin-left: 0;
    margin-right: 1rem;
  }
}

.container{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  // justify-content: space-between;
  align-items: center;
  gap: $col-margin;

  .individualItem{
      // margin-top: 1.2%;
      // 4 columns by default
      width: 27%;
      margin-top: 0.3rem;
      position: relative;
      color: #333333;
      /// / 2 columns on small screens
      @media only screen and (max-width: #{$grid__bp-md}px) {
        width: calc(50% - #{$col-margin});
      }
  }
  .individualItemName{
    width: 65%;
      margin-top: 0.3rem;
      position: relative;
      color: #333333;
      @media only screen and (max-width: #{$grid__bp-md}px) {
        width: calc(50% - #{$col-margin});
      }
  }
  .icon {
    margin-top: 5%;
    min-width: 7%;
    display: flex;
    align-items: center;
    @media only screen and (max-width: #{$grid__bp-md}px) {
      min-width: 12%;
      margin-top: 7%;
    }
  }
  .addButton{
    min-width: 7%;
  }
}
.containerNewVendor{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  // justify-content: space-between;
  align-items: left;
  gap: $col-margin;

  .addButton{
    min-width: 7%;
  }
}

.individualItem{
  // margin-top: 1.2%;
  // 4 columns by default
  width: 16%;
  // width: 18%;
  /// / 2 columns on small screens
  @media only screen and (max-width: #{$grid__bp-md}px) {
    width: calc(40% - #{$col-margin});
  }
}

.passwordBox {
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 0.75rem);
}

.addressSelector {
  display: flex;
  flex-direction: column;
}

div.locationInput {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    max-width: 60%;
  }
}

.borderBottom {
  border-bottom: 2px solid $mono-default-light;
}

.input {
  margin-top: 0.5rem;
  flex-basis: calc(50% - 0.75rem);
}

.footer {
  box-shadow: -5px -11px 16px 4px rgba(0, 0, 0, 0.08);
  background-color: $mono-white;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    background-color: $blue-lightest;
  }
}

.formHeaderContainer {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin-bottom: 20px;
}

.bookingLegend {
  flex: 0 0 300px;
  margin-left: 20px;
  font-size: 14px;
}

.legendItem {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.colorBox {
  width: 40px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 4px;
  border: 2px solid $mono-default-light;
}

.blue {
  background-color: $blue-lighter;
}

.notice {
  font-size: 14px;
}

.strongText {
  font-weight: bold;
  color: inherit;
}
