@import '../utilities/variables';

.buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1.5rem;
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    padding: 0;
  }
}
.vehicleInformation {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    padding: 24px 37px;
  }
}

.header {
  padding: 0;
  padding-bottom: 0.6rem;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1.5rem;
  }
}
.selectors {
  padding: 1.5rem;
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    padding: 1.5rem 0;
    display: flex;
    gap: 1rem;
  }
}
