@import '../utilities/variables';

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 auto;
  padding: 0 1rem 0rem;
  color: $mono-black;

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    padding: 0 1rem 0 1rem;
  }

  p {
    margin: 1rem 0 1rem 0;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: -1%;
  }
  .container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    // justify-content: space-between;
    gap: $col-margin;
    label {
      width: 100%;
      // remove margin bottom due to flex gap above
      margin-bottom: 0;
    }
    .individualItem{
        // margin-top: 1.2%;
        // 4 columns by default
        width: 16%;
        // width: 18%;
        /// / 2 columns on small screens
        @media only screen and (max-width: #{$grid__bp-md}px) {
          width: calc(40% - #{$col-margin});
        }
    }
    .icon {
      margin-top: 5%;
      min-width: 7%;
      display: flex;
      align-items: center;
      @media only screen and (max-width: #{$grid__bp-md}px) {
        min-width: 12%;
        margin-top: 7%;
      }
    }
  }
  .addButton{
    min-width: 7%;
  }
  .poNumber{
    width: 50%;
  }
  

}