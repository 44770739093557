@import '../utilities/variables';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    height: auto;
    max-height: 100vh;
  }
}

.input_box {
  width: 55%;

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    width: 100%;
  }
}

.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    justify-content: space-between;
    margin-top: 1rem;
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 auto;
  padding: 0 1rem 1rem;
  color: $mono-black;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    padding: 1rem;
  }
}

.button {
  margin: 0 1rem;
  @media only screen and (max-width: #{$grid__bp-md}px) {
    padding: 1rem 2rem;
    width: 45%;
    margin: 0;
  }
}