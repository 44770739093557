@import '../utilities/variables';

.button {
  @media only screen and (min-width: #{$grid__bp-md}px) {
    min-width: 3rem;
  }
  height: 2.5rem;
  border: none;
  cursor: pointer;
  padding: 0 0.5rem;
  @media only screen and (max-width: #{$grid__bp-md}px) {
    padding: 0.25rem 0.5rem;
  }
  border: 1px solid transparent;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 0;
}

.primary {
  color: $mono-white;
  background-color: $orange-darker;

  &:hover {
    background-color: $orange-default;
  }

  &:focus {
    border: 1px solid $orange-darkest;
  }

  &:active {
    background-color: $orange-darkest;
  }
}

.secondary {
  color: $blue-darkest;
  background-color: $mono-white;
  border: 1px solid $mono-default-dark;

  &:hover {
    background-color: $blue-lightest;
  }

  &:focus {
    border-color: $blue-default;
  }

  &:active {
    background-color: $blue-lightest;
    border-color: $blue-default;
  }
}

.tertiary {
  color: $blue-darkest;
  background-color: transparent;
  text-decoration: underline;
  border: 1px solid transparent;

  &:hover {
    background-color: $blue-lightest;
  }

  &:focus {
    border-color: $blue-default;
  }

  &:active {
    background-color: transparent;
    border-color: transparent;
    color: $orange-darkest;
  }

  &.inactive {
    color: $mono-default-dark;
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
}

.link {
  color: $mono-black;
  background-color: transparent;
  border: none;
  justify-content: left;

  &:hover {
    text-decoration: underline;
  }
}

.small {
  height: 2.5rem;
}

.large {
  height: 3rem;
}

.icon {
  min-width: 3rem;
  width: 3rem;
  height: 3rem;
  border-radius: 3rem !important;
  background-color: transparent;
  color: $mono-black;

  &:hover {
    background-color: $blue-lightest;
  }

  &:focus {
    border-color: $blue-default;
  }

  &:active {
    border-color: transparent;
  }

  &.inactive {
    color: $mono-default-dark;
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
}

.inactive {
  background-color: $mono-default-dark;
  color: $mono-white;
  cursor: not-allowed;

  &:hover {
    background-color: $mono-default-dark;
  }
}

.error {
  color: $red-dark;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  gap: 0;

  .button:only-of-type {
    border-radius: 4px;
  }

  .button:last-of-type {
    border-radius: 0 4px 4px 0;
  }

  .button:not(:last-of-type) {
    border-right: none;
    border-radius: 0;
  }

  .button:first-of-type {
    border-radius: 4px 0 0 4px;
  }
}
