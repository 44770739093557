@import '../utilities/variables';

.body {
  padding: 0 1rem 0rem;
  color: $mono-black;

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    padding: 0 1rem 0 1rem;
  }

  p {
    margin: 1rem 0 1rem 0;
  }
}
