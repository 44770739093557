@import '../utilities/variables';

.loading {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 50%;
}

.workOrderDetailsContainer {
  display: flex;
  flex-direction: column;
}

.editButton {
  min-width: 8.75rem;
}
.content {
  padding-left: $container-padding-lg;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-left: 0px;
  }
}

.unavailabilityReasonBadge {
  margin-left: 1rem;
}

.saveCancelButton {
  margin: 0.2rem;
}

.editScheduledAtTitle {
  padding-left: 48px;
  padding-top: 16px;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-left: 16px;
  }
}

.editScheduledAt {
  flex-wrap: wrap;
  margin-right: auto;
}

.backButtonContainer {
  padding: 0.625rem 1rem;
  background-color: $blue-lightest;
  border-bottom: 2px solid $mono-light-grey;
  h5 {
    font-weight: 700;
  }
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1rem;
  }
}

.backButtonContent {
  display: flex;
  align-items: center;
  padding-left: 24px;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-left: 0px;
  }
}

.caret {
  background-color: inherit;
  border: none;
  cursor: pointer;
  border-radius: $border-radius-lg;
  height: 32px;
  width: 32px;
  transform: rotate(90deg);
  display: flex;
  align-items: flex-end;
  padding-bottom: 0;
}

.completeWorkOrderButtonContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1.3rem 0;
  padding: 0 1rem;
  button {
    width: 100%;
  }
}

.deleteButton {
  padding: 1px;
  min-width: 8.75rem;
}
.assignButton {
  width: 30%;
  margin-left: 35%;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    margin-left: auto;
  }
}
