@import '../utilities/variables';

.passwordShow {
  min-width: 0;
  padding: 0 1.5rem;

  border-radius: 0;

  color: $blue-default;
  background-color: $mono-lighter;

  input:focus + & {
    @media only screen and (min-width: #{$grid__bp-md}px) {
      border: 1px solid $mono-black;
      border-left: none;
      outline: none;
    }
  }

  &:hover {
    background-color: $mono-lighter;
    color: $blue-lighter;
  }

  &:focus {
    border: none;
  }

  &:active {
    background-color: $mono-lighter;
    color: $blue-darkest;
  }
}
