@import '../utilities/variables';

.success {
  background-color: $blue-lightest;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: 1.5rem;
  padding: 0 4rem;
  padding-top: 3rem;
  @media only screen and (max-width: #{$grid__bp-xl}px) {
    padding: 0 2rem;
  }
}

.body {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3.4375rem;
  padding: 0 4rem;
  flex: 1 1 1px;
  overflow: auto;
  max-height: 100%;
  min-height: 0;
  display: flex;

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
  }
}

.bookingInfoSection {
  padding: 2rem 1.75rem 1rem 1.75rem;
  background-color: $mono-white;
  flex-basis: 50%;
  box-shadow: $box-shadow;
  max-width: 860px;
  min-width: 640px;

  @media only screen and (max-width: #{$grid__bp-xl}px) {
    width: 100%;
    min-width: 100%;
  }
}

.date {
  font-weight: 700;
}

.subheader {
  margin-top: 1.125rem;
  margin-bottom: 0.5rem;
}

.invoice {
  background-color: $mono-lighter;
  padding: 1rem 1.75rem;
  margin-bottom: 0.75rem;
}

.mapSection {
  position: relative;
  min-width: calc(min(360px, 100%));
}

.map {
  width: 100%;
  object-fit: contain;
}

.secretDiv {
  position: absolute;
  top: 39%;
  left: 50%;
}

.popper {
  background-color: $mono-white;
  padding: 1.25rem;
  width: 15rem;
  border-radius: 4px;
  box-shadow: $box-shadow;

  display: flex;
  flex-direction: column;
}

.popperHeader {
  font-weight: 700;
  margin-bottom: 1.125rem;
}

.popperSection {
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
}

.icon {
  width: 1.5rem;
  margin-right: 1rem;
}

.footer {
  z-index: 2;
  padding: 1.5rem 2rem;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $mono-white;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  gap: 1rem;
  box-shadow: -2px 2px 8px 4px rgba(0, 0, 0, 0.08);

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    background-color: $blue-lightest;
  }
}
