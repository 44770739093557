@import '../utilities/variables';

.modal {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 629px;
  background-color: $mono-white;
  top: 50%;
  left: 50%;
  overflow: auto;
  max-height: 100vh;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    width: 80%;
    max-height: 85%;
    overflow: auto;
  }
}
