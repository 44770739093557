@import '../utilities/variables';

.body {
  flex: 1 1 0px;
  padding-top: 5%;
  background-color: $blue-lightest;
  @media only screen and (min-width: 767px) {
    background-image: url('../../images/gooil-car.png');
    background-repeat: no-repeat;
    background-position: 90% 90%;
    background-size: 40%;
  }
  @media only screen and (max-width: 767px) {
    flex-basis: auto;
  }
}

.header {
  background-color: $blue-lightest;
  padding: 0.75rem;
  display: flex;
  width: 100%;

  @media only screen and (min-width: #{$grid__bp-md}px) {
    display: none;
  }
}

.headerWrapper,
.headerWrapperBlue {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $mono-black;
  margin-bottom: 1.25rem;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    padding: 1.5rem;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-bottom: 1rem;
    background: rgba(255, 255, 255, 0.8);
  }
}

.headerWrapperBlue {
  background-color: $blue-lightest;
  padding-bottom: 1rem;

  @media only screen and (max-width: #{$grid__bp-md}px) {
    background-color: $blue-lightest;
  }
}

.mobileHeader {
  @media only screen and (min-width: #{$grid__bp-md}px) {
    display: none;
  }
}

.desktopHeader {
  @media only screen and (max-width: #{$grid__bp-md}px) {
    display: none;
  }
}

.card {
  background: rgba(255, 255, 255, 0.8);
  padding: 2.5rem;

  form {
    flex: 1 0 auto;
    width: auto;
    display: flex;
    flex-direction: column;
  }

  ul,
  li {
    list-style: inside;
  }
}

.sentEmailSuccess {
  margin: 3.625rem 0;
}

.link {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.bottomLink {
  margin-bottom: 1.75rem;
  margin-top: 1.25rem;
  align-self: center;
}

.cardColumn {
  padding: 0 2.5rem;
}

.firstCardColumn {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    border-bottom: 2px solid $mono-default-light;
    padding: calc(2.5rem - 2px) 0;
    padding-top: 0;
  }

  @media only screen and (min-width: #{$grid__bp-lg}px) {
    border-right: 2px solid $mono-default-light;
    padding: 0 calc(2.5rem - 2px);
    padding-left: 0;
  }
}

.lastCardColumn {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 2.5rem 0;
    padding-bottom: 0;
  }

  @media only screen and (min-width: #{$grid__bp-lg}px) {
    padding: 0 2.5rem;
    padding-right: 0;
  }
}

.centered {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobileHintText {
  margin-top: 1rem;

  ul {
    margin-top: 0.75rem;
    padding-left: 0.5rem;
    align-self: flex-start;
  }
  @media only screen and (min-width: #{$grid__bp-md}px) {
    display: none;
  }
}

.fullWidthBottomButton {
  margin-top: 1.5rem;
  width: 100%;
}
